import React, { useState } from 'react';
import {
  Container, Row, Col, TabContent, TabPane, NavLink, NavItem, Nav
} from 'reactstrap';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Layout, Button, Faq } from '../../Components';

const Culture = ({ data }) => {
  const [activeTab, setActiveTab] = useState('1');
  const [activeContent, setActiveContent] = useState('1');
  return (
    <Layout>
      <Container className="my-5">
        <Row>
          <Col md="6" className="my-5">
            <div className="Header__text pr-5 pl-3 pt-3">
              <h2>Le sous-programme culture</h2>
              <p>
                Doté de 31% du budget global et dédié aux professionnels de la culture et de la
                création (spectacle vivant, art contemporain, musiques, patrimoine, etc.). Il
                soutient, à travers la coopération, la mise en réseau et le développement de
                plateformes, des projets innovants portés par des structures professionnelles
              </p>
            </div>
            <div
              className={
                activeTab === '1'
                  ? 'd-block w-100 HeaderButton__tab HeaderButton__activeTab my-5'
                  : 'd-block w-100 HeaderButton__tab my-5'
              }
            >
              <Button
                color="secondary"
                target="#"
                text="Appel coopération"
                onClick={() => setActiveTab('1')}
              />
            </div>
            <div
              className={
                activeTab === '2'
                  ? 'd-block w-100 HeaderButton__tab HeaderButton__activeTab'
                  : 'd-block w-100 HeaderButton__tab'
              }
            >
              <Button
                color="secondary"
                target="#"
                text="Appel traduction littéraire"
                onClick={() => setActiveTab('2')}
              />
            </div>
          </Col>
          <Col md="6" className="p-3 p-md-1">
            <Img
              fluid={data.file.childImageSharp.fluid}
              className="img-fluid mx-auto d-none d-md-block"
            />
          </Col>
        </Row>
      </Container>
      <Container className="my-5" id="Content">
        <Row>
          <Col sm="4">
            <Nav vertical className="contentTabs__program">
              <NavItem>
                <NavLink
                  href="#"
                  className={activeContent === '1' ? 'active' : ''}
                  onClick={() => {
                    setActiveContent('1');
                  }}
                >
                  Pourquoi ?
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  className={activeContent === '2' ? 'active' : ''}
                  onClick={() => {
                    setActiveContent('2');
                  }}
                >
                  Pour qui ?
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  className={activeContent === '3' ? 'active' : ''}
                  onClick={() => {
                    setActiveContent('3');
                  }}
                >
                  Projets
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  className={activeContent === '4' ? 'active' : ''}
                  onClick={() => {
                    setActiveContent('4');
                  }}
                >
                  FAQ
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
          <Col md="8">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <p>
                  Cet appel soutient les acteurs culturels et créatifs dans leurs démarches de
                  collaboration à travers l’Europe afin de renforcer les capacités internationales
                  des professionnels et des artistes, et de promouvoir l’innovation et la
                  créativité.
                </p>
                <TabContent activeTab={activeContent}>
                  <TabPane tabId="1">
                    <div className="Header__text pr-5 pl-3 pt-3">
                      <h4 className="text-uppercase">Pourquoi?</h4>
                      <p>
                        Développer la capacité des secteurs culturels et créatifs à opérer à
                        l’échelle transnationale et internationale à travers la promotion de la
                        circulation des œuvres culturelles et créatives et la mobilité des
                        professionnels et des artistes
                      </p>
                      <p>
                        Renforcer le développement des publics pour améliorer l’accès aux œuvres
                        culturelles et créatives et au patrimoine culturel matériel et immatériel de
                        l’Europe.
                      </p>
                      <p>
                        Favoriser le renforcement des capacités à travers des approches innovantes
                        en matière de création, d’élaboration et d’expérimentation de nouveaux
                        modèles en particulier en ce qui concerne le passage au numérique.
                      </p>
                      <p>
                        Renforcer le dialogue interculturel, la compréhension mutuelle et le respect
                        des autres cultures.
                      </p>
                    </div>
                  </TabPane>
                  <TabPane tabId="2">
                    <div className="Header__text pr-5 pl-3 pt-3">
                      <h4 className="text-uppercase">Pour qui?</h4>
                      <p>
                        L’appel coopération s’adresse à l’ensemble des acteurs (public ou privé) de
                        tous secteurs culturels et créatifs (spectacle vivant, mode, design, jeux
                        vidéo, patrimoine, arts visuels, etc.) souhaitant expérimenter, avec
                        d’autres acteurs culturels et créatifs européens, de nouvelles manières de
                        faire et de travailler à l’échelle européenne.
                      </p>
                    </div>
                  </TabPane>
                  <TabPane tabId="3">
                    <div className="Header__text pr-5 pl-3 pt-3">
                      <h4 className="text-uppercase">Projets</h4>
                      <p>
                        <b>Les projets de Coopération à petite échelle</b>
                        : doivent concerner au
                        moins 3 partenaires, dont le chef de file, d’au moins trois pays éligibles
                        différents. Le financement européen ne peut excéder 200 000 euros,
                        représentant 60 % du budget global éligible.
                      </p>
                      <p>
                        <b>Les projets de Coopération à grande échelle</b>
                        : doivent concerner au
                        moins 6 partenaires, dont le chef de file, d’au moins 6 pays éligibles
                        différents. Le financement européen ne peut excéder 2 millions d’euros,
                        représentant 50 % du budget global éligible.
                      </p>
                      <p>
                        Quelles que soient leurs échelles, les projets ne peuvent dépasser une durée
                        de 48 mois.
                      </p>
                    </div>
                  </TabPane>
                  <TabPane tabId="4">
                    <Faq />
                  </TabPane>
                </TabContent>
              </TabPane>
              <TabPane tabId="2">
                <p>
                  Les projets de traduction littéraire visent à promouvoir, à soutenir et à diffuser
                  la littérature européenne de qualité en vue d’assurer son accessibilité.
                </p>
                <TabContent activeTab={activeContent}>
                  <TabPane tabId="1">
                    <div className="Header__text pr-5 pl-3 pt-3">
                      <h4 className="text-uppercase">Pourquoi?</h4>
                      <p>Favoriser la diversité culturelle et linguistique en Europe.</p>
                      <p>
                        Encourager la circulation transnationale d’œuvres littéraires de qualité,
                        améliorer l’accès à ces œuvres et atteindre de nouveaux publics.
                      </p>
                      <p>
                        Soutenir la promotion de la littérature européenne, notamment le bon usage
                        des technologies numériques tant dans la distribution que dans la promotion
                        des œuvres.
                      </p>
                      <p>
                        Encourager la traduction des œuvres ayant bénéficié du prix littéraire
                        européen et des œuvres écrites dans des langues moins utilisées vers le
                        français, l’anglais, l’espagnol et l’allemand.
                      </p>
                    </div>
                  </TabPane>
                  <TabPane tabId="2">
                    <div className="Header__text pr-5 pl-3 pt-3">
                      <h4 className="text-uppercase">Pour qui?</h4>
                      <p>
                        Cet appel est destiné aux éditeurs et aux maisons d’éditions avec une
                        personnalité juridique d’au moins 2 ans, et ayant leur siège social dans un
                        pays éligible du programme.
                      </p>
                      <p>
                        La traduction doit être faite depuis et vers une langue éligible (langues
                        officielles d’un pays participant, latin ou grec), et concerner une œuvre de
                        fiction déjà publiée et non traduite.
                      </p>
                    </div>
                  </TabPane>
                  <TabPane tabId="3">
                    <div className="Header__text pr-5 pl-3 pt-3">
                      <h4 className="text-uppercase">Projets</h4>
                      <p>
                        <b>Catégorie 1 : projets d’une durée de deux ans</b>
                      </p>
                      <p>
                        Projets visant la traduction, la promotion et la distribution de 3 à 10
                        œuvres de fiction depuis et vers des langues éligibles sur une période de 2
                        ans.
                      </p>
                      <p>
                        Le financement européen ne peut excéder 100 000 euros, soit 50 % du budget
                        global éligible.
                      </p>
                      <p>
                        <b>Catégorie 2 : convention – cadre de partenariat</b>
                      </p>
                      <p>
                        Projets visant la traduction, la promotion et la distribution de 5 à 10
                        œuvres de fiction depuis et vers des langues éligibles par an sur une
                        période de 3 ans.
                      </p>
                      <p>
                        Le financement européen ne peut excéder 100 000 euros par an, soit 50 % du
                        budget global éligible.
                      </p>
                    </div>
                  </TabPane>
                  <TabPane tabId="4">
                    <Faq />
                  </TabPane>
                </TabContent>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Culture;
export const query = graphql`
  query {
    file(relativePath: { eq: "culture.png" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
